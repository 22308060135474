@import url("https://fonts.googleapis.com/css?family=Paytone+One&display=swap");
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700&display=swap");

$primaryColor: #030304;
$secondaryColor: #0d0809;
$primaryFont: "Paytone One", sans-serif;
$secondaryFont: "Nunito", sans-serif;

.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  .navbar {
    width: 100%;

    .navbar-brand {
      color: red !important;
      font-weight: 900;
      font-size: 2.5rem;
      position: relative;
    }

    .navbar-toggler {
      background: none;
      border: none;
      outline: none !important;
      color: white;
    }

    .navbar-toggler-icon {
      color: white;
      padding-left: 50%;
    }

    .navbar-collapse {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 999;
      display: none;

      &.show {
        display: flex;
        background: $secondaryColor;
      }

      @media (min-width: 992px) {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .navbar-nav {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: 992px) {
        flex-direction: row;
        align-items: center;
      }
    }

    ul li a.nav-link {
      color: white;
      padding: 1rem;
      border-bottom: 1px solid $secondaryColor;

      @media (min-width: 992px) {
        padding-right: 30px !important;
        padding-left: 30px !important;
        // padding-top: 40px !important;
        border-bottom: none !important;
        font-family: $secondaryFont !important;
        font-weight: bold !important;
      }
    }

    .mt-lg-4 {
      margin-top: 1.5rem;

      @media (min-width: 992px) {
        margin-top: 0;
      }
    }

    .mx-lg-5 {
      margin-left: 3rem;
      margin-right: 3rem;

      @media (min-width: 992px) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

/* Mobile phones (portrait) */
@media (max-width: 480px) {
  .header-transparent {
    .navbar {
      padding-top: 5%;

      .navbar-brand {
        margin-left: 3%;
      }

      .navbar-collapse {
        background: $primaryColor;
      }

      ul li a.nav-link {
        color: white;
        padding: 1rem;
        border-bottom: 1px solid $secondaryColor;
      }
    }
  }
}

/* Mobile phones (landscape) - iPhone X */
@media (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  .header-transparent {
    .navbar {
      margin-top: 13px;

      .navbar-collapse {
        background: $primaryColor;
      }

      ul li a.nav-link {
        color: white;
        padding: 1rem;
        border-bottom: 1px solid $secondaryColor;
      }
    }
  }
}

/* Mobile phones (landscape) - iPhone 6/7/8 */
@media (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape) {
  .header-transparent {
    .navbar {
      margin-top: 13px;

      .navbar-collapse {
        background: $primaryColor;
      }

      ul li a.nav-link {
        color: white;
        padding: 1rem;
        border-bottom: 1px solid $secondaryColor;
      }
    }
  }
}

/* Tablets (portrait) */
@media (min-width: 768px) and (max-width: 1024px) {
  .header-transparent {
    .navbar {
      margin-top: 13px;

      .navbar-collapse {
        background: $secondaryColor;
      }

      ul li a.nav-link {
        color: white;
        padding: 1rem;
        border-bottom: 1px solid $primaryColor;
      }
    }
  }
}

/* Tablets (landscape) */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .header-transparent {
    ul li a.nav-link {
      border-bottom: none;
    }
  }
}

/* iPad Pro (Portrait and Landscape) */
@media (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .header-transparent {
    ul li a.nav-link {
      border-bottom: none !important;
    }
  }
}
