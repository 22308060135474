@import url("https://fonts.googleapis.com/css?family=Paytone+One&display=swap");
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Courgette&display=swap");

/* colors */
$primaryColor: #0d0809;
$secondaryColor: #030304;
$thirdColor: #ff0000d8;
$forthColor: #141415;

/* fonts */
$primaryFont: "Paytone One", sans-serif;
$secondaryFont: "Nunito", sans-serif;
$thirdFont: "Courgette", cursive;

/* sizes */
$titleFontSize: 65px;

* {
  cursor: none;
}

.App {
  text-align: center;
  background: #171616;
}

/* global classes */

.title-font {
  font-family: ethnocentric, sans-serif; ;
  line-height: 130%;
}

.title-font-2 {
  font-family: $secondaryFont;
  line-height: 130%;
}

.title-font-3 {
  font-family: $thirdFont;
  line-height: 130%;
}

.title-font-size {
  font-size: $titleFontSize;
}

.section-title {
  padding-top: 10%;
}

/* --------------------- Custom Cursor --------------------- */

.custom-cursor {
  cursor: none; 
  position: fixed;
  pointer-events: none; /* Prevents interference with mouse events */
  z-index: 9999; /* Ensure the cursor is on top of other content */
}

.custom-cursor .circle {
  position: absolute;
  border-radius: 50%;
  border: 2px solid rgb(255, 255, 255); /* Border color */
  background: transparent; /* Transparent background */
}

.custom-cursor .circle.small {
  width: 20px; /* Size of the small circle */
  height: 20px;
  background: #fff;
}

.custom-cursor .circle.large {
  width: 30px; /* Size of the large circle */
  height: 30px;
  border-color: rgb(0, 255, 34); /* Border color of the large circle */
}



/* --------------------- Banner --------------------- */

.banner {
  color: white;
  // margin-top: 60px;
}

.banner-image-mobile {
  display: none;
}

.banner-image {
  width: 90%;
}

.banner-text {
  margin-bottom: 30px;
}

.banner-text h1 {
  font-size: 62px;
}

.banner-text .text-yellow {
  color: #7ced03;
}

.banner-text .text-customer {
  color: #7ced03;
}

/*----------------------About us area--------------------------*/

.about-us-area {
  background: $thirdColor no-repeat;
  // background-image: url("./img/shape2.png");
  background-position: center;
  background-size: 400px;
  padding: 5%;

  .about-us-text-area {
    padding: 3em;
    background: $forthColor;
    -webkit-box-shadow: 0px 0px 46px -17px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 46px -17px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 46px -17px rgba(0, 0, 0, 0.75);

    h2 {
      font-weight: 900;
    }
  }
}

/*---------------------- portfolio area --------------------------*/

.portfolio {

  .portfolio-title {
    padding: 0px 0px 40px 0px;

    h1 {
      font-size: $titleFontSize;
    }

    p {
      font-size: 1.6rem;
    }
  }

  .portfolio-thumb {
    margin: 0 auto;
    margin-bottom: 9%;

    /* width: 100%;
        height: 100%; */
    float: left;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: default;

    img {
      width: 100%;
      transition: transform 0.25s, visibility 0.25s ease-in;
    }
    :hover img {
      transform: scale(1.5);
    }
    .image-container {
      .hover-content {
        width: 100% !important;
        height: 100% !important;
        background: $primaryColor;
      }
      .title {
        color: white;
        opacity: 1;
        margin-top: 40%;
        font-weight: 900;
        font-size: 30px;
        font-family: $secondaryFont;
      }
      .hover-content.fadeIn {
        opacity: 0.9;
      }
    }
  }
}

/*---------------------- lead area --------------------------*/

.lead {
  background: $thirdColor;

  h1 {
    font-size: $titleFontSize;
  }

  button {
    border-radius: 7px 7px 7px 7px;
    -moz-border-radius: 7px 7px 7px 7px;
    -webkit-border-radius: 7px 7px 7px 7px;
    // border: 2px solid #00ff00;
    height: 50px;
    width: 50%;
    background: $primaryColor;
    font-family: $secondaryFont;
    font-weight: 900;
    font-size: 100%;

    :hover {
      text-decoration: none;
    }

    a {
      color: #ffff;
    }
  }
  button:hover{
    border: 3px solid #000000;
  }
}
/*---------------------- blogs page --------------------------*/

.sort-options .dropdown-toggle {
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
}
.sort-options .dropdown-menu {
  // display: block;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}




.pagination-dark .page-item .page-link {
  color: #fff;
  background-color: #333;
  border: 0.001rem solid #444;
  // border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.pagination-dark .page-item.active .page-link {
  background-color: #f00;
  color: #fff;
}

.pagination-dark .page-item.disabled .page-link {
  color: #666;
  background-color: #333;
  cursor: not-allowed;
}

.pagination-dark .page-link:hover {
  background-color: #444;
  color: #fff;
}
.pagination-dark .page-link:focus {
  box-shadow: none
}

.blog-image {
  width: 100%; /* Make the image take full width of the card */
  height: 240px; /* Set a fixed height */
  object-fit: cover; /* Cover the area while maintaining aspect ratio */
}

.blog-card-body {
  height: 180px; /* Set a fixed height */
  overflow: hidden; /* Hide any overflow text */
}

.card-title {
  font-size: 1.6rem;
  // height: 40px; /* Set a fixed height for the title */
  overflow: hidden; /* Hide any overflow text */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
  white-space: nowrap; /* Prevent text from wrapping */
}

.card-text {
  font-size: 0.8rem;
}


/*---------------------- footer area --------------------------*/
footer {
  background: $thirdColor;
}

/*---------------------------------------------------------------*/
/*---------------------- contact page ---------------------------*/
/*---------------------------------------------------------------*/

.contact {
  p {
    font-size: 1.6rem;
  }

  .contact-form {
    padding-bottom: 10%;

    form-group:focus {
      outline: none;
    }

    input,
    textarea {
      background: transparent;
      font-family: $secondaryFont;
      border-bottom: 1px solid white;
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      padding: 4%;
      color: white;
    }

    input::placeholder,
    textarea::placeholder {
      color: white;
      opacity: 0.5;
    }

    input:focus,
    textarea:focus {
      outline: none;
      box-shadow: none;
    }

    button {
      border-radius: 7px 7px 7px 7px;
      -moz-border-radius: 7px 7px 7px 7px;
      -webkit-border-radius: 7px 7px 7px 7px;
      border: 0px solid #000000;
      height: 50px;
      width: 50%;
      background: $secondaryColor;
      font-family: $secondaryFont;
      color: white;
      font-weight: 900;
      font-size: 100%;

      :hover {
        text-decoration: none;
      }

      :focus {
        outline: white;
        box-shadow: white;
      }
    }
  }
}

/*---------------------------------------------------------------*/
/*---------------------- services page ---------------------------*/
/*---------------------------------------------------------------*/

.services {
  .services-title {
    padding-bottom: 5%;
  }
  .title-text {
    font-size: 1.6rem;
  }

  .service-name {
    font-weight: 900;
    font-size: 25px;
  }

  .service-boxes {
    padding: 10%;
    border-top: 1px solid #272626;
    .service-icon {
      position: absolute;
      right: 90%;
    }

    img {
      width: 90px;
    }

    .service-info-title {
      height: 25px;
      margin-bottom: 5%;
  
    }

    .service-description {
      font-size: 20px;
    }
  }
  .service-boxes:hover{
    background-color: #272626;
    border-radius: 30px;
  }
}

/*---------------------------------------------------------------*/
/*--------------- portfolio detail page --------------*/
/*---------------------------------------------------------------*/

.detail {
  .services-title {
    padding-bottom: 5%;
  }
  .detail-image {
    width: 100%;
  }

  .detail-info {
    .work-title {
      font-weight: 900;
    }

    .description {
      line-height: 1.6rem;
      font-size: larger;
    }

    hr {
      border-color: white;
      border-style: solid;
    }
  }
}

/*---------------------------------------------------------------*/
/*--------------- team page --------------*/
/*---------------------------------------------------------------*/

.team {
  margin-bottom: 10%;

  .portfolio-thumb {
    margin: 0 auto;
    margin-bottom: 5%;

    /* width: 100%;
        height: 100%; */
    float: left;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: default;

    img {
      width: 100%;
      transition: transform 0.25s, visibility 0.25s ease-in;
    }
    :hover img {
      transform: scale(1.5);
    }

    .image-container {
      .hover-content {
        width: 100% !important;
        height: 100% !important;
        background: $primaryColor;
      }
      .title {
        color: white;
        opacity: 1;
        margin-top: 30%;
        font-weight: 900;
        font-size: 30px;
        font-family: $secondaryFont;
      }
      .hover-content.fadeIn {
        opacity: 0.9;
      }
    }
  }

  p {
    font-size: 1.6rem;
  }

  .person-info {
    visibility: visible;
    display: none;
  }
}
/* for mobile phones (portrait) */
@media (min-width: 320px) and (max-width: 480px) {
  /* global styles */
  .section-title {
    padding-top: 0%;
  }
  /* global styles end */

  .title-font-size {
    font-size: -webkit-xxx-large;
  }

  .banner {
    padding: 0px 0 180px;
  }

  .banner-image-desktop {
    display: none;
  }

  .banner-image-mobile {
    display: block;
  }

  .banner-text {
    padding-top: 10%;
  }

  .banner-text h1 {
    font-size: 48px;
    margin-bottom: 4%;
    padding: 4%;
    margin-top: 4%;
  }

  .banner-image {
    width: 90%;
  }

  /*----------------------About us area--------------------------*/

  .about-us-area {
    background-size: 400px;
    .about-us-text-area {
      h2 {
        font-size: 25px;
      }
    }
  }

  /*----------------------portfolio area--------------------------*/
  .portfolio {
    .portfolio-title {
      padding: 0;
      padding-top: 15%;

      h1 {
        font-size: -webkit-xxx-large;
      }
    }
  }

  /*----------------------lead area--------------------------*/
  .lead {
    h1 {
      font-size: -webkit-xxx-large;
    }
  }
  /*----------------------contact page--------------------------*/

  .contact {
    p {
      font-size: 1.6rem;
    }
  }
  /*----------------------services page--------------------------*/

  .services {
    .services-title {
      padding-bottom: 0;
      margin-top: 100px;
    }
    .service-boxes {
      .service-icon {
        position: unset;
      }

      .service-info-title {
        height: 50px;
        margin-bottom: 5%;

        .service-name {
          font-size: 20px;
        }
      }
     
      .service-description {
        text-align: center !important;
        font-size: 15px;
      }
    }
    
    .title-text {
      font-size: 1.6rem;
    }
  }
  /*----------------------team page--------------------------*/

  .team {
    .person-info {
      visibility: visible;
      display: inline;

      .person-position {
        font-size: 15px;
        margin-bottom: 15%;
      }
    }
  }

  /*----------------------contact page--------------------------*/
  
    .contact-title {
      margin-top: 100px;
    }

}

/* for desktop */
@media (min-width: 992px) {
  .banner {
    padding: 190px 0 125px;

    .banner-text {
      // padding-top: 39%;
    }
  }
}

/* for mobile phones (landscape) */
/* iphone X */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  .banner {
    padding: 0px 0px 100px !important;

    .banner-text {
      padding-top: 10% !important;

      h1 {
        font-size: 48px;
        text-align: center;
      }
    }

    .banner-image {
      width: 23% !important;
      margin-top: 30px;
    }
  }

  .portfolio {
    .portfolio-title {
      padding: 0;
    }
  }

  .services {
    .services-title {
      padding-bottom: 0;
    }
    .service-boxes {
      .service-icon {
        position: unset !important;
      }

      .service-description {
        text-align: center !important;
      }
    }
  }

  .team {
    .person-info {
      visibility: visible;
      display: inline;

      .person-position {
        font-size: 15px;
        margin-bottom: 15%;
      }
    }
  }

  /* global styles */
  .section-title {
    padding-top: 0%;
  }
}

/* for mobile phones (landscape) */
/* iphone 6/7/8 */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape) {
  .banner {
    padding: 0px !important;
    .banner-text {
      padding-top: 10%;

      h1 {
        font-size: 48px;
        text-align: center;
      }
    }
    .banner-image {
      width: 50%;
      margin-top: 30px;
    }
  }

  .portfolio {
    .portfolio-title {
      padding: 0;
    }
  }

  .team {
    padding-top: 0%;

    .person-info {
      visibility: visible;
      display: inline;

      .person-position {
        font-size: 15px;
        margin-bottom: 15%;
      }
    }
  }
}

/* pixel 2 xl (landscape) */
@media only screen and (min-width: 411px) and (max-width: 767px) {
  .team {
    padding-top: 0%;

    .team-title {
      h1 {
        font-size: 75px;
      }
    }
    .person-info {
      visibility: visible;
      display: inline;

      .person-position {
        font-size: 15px;
        margin-bottom: 15%;
      }
    }
  }
}

/* pixel 2 */
@media only screen and (min-width: 411px) and (max-width: 767px) {
  .team {
    padding-top: 0%;

    .team-title {
      margin-top: 0rem;

      h1 {
        font-size: 75px;
      }
    }
    .person-info {
      visibility: visible;
      display: inline;

      .person-position {
        font-size: 15px;
        margin-bottom: 15%;
      }
    }
  }
}
/* tablets (portrait) */
@media (min-width: 768px) and (max-width: 1024px) {
  .banner {
    padding: 0px 0px 100px;
    .banner-text {
      padding-top: 10%;

      h1 {
        font-size: 48px;
        text-align: center;
      }
    }
    .banner-image {
      width: 50%;
      margin-top: 30px;
    }
  }
  .contact {
    padding-bottom: 10%;

    .contact-form {
      padding-bottom: 30%;
    }
  }

  .services {
    .service-boxes {
      .service-icon {
        position: unset !important;
      }

      .service-description {
        text-align: center !important;
      }
    }
  }

  .team {
    padding-top: 0%;

    .team-title {
      h1 {
        font-size: 75px;
      }
    }
    .person-info {
      visibility: visible;
      display: inline;

      .person-position {
        font-size: 15px;
        margin-bottom: 15%;
      }
    }
  }
}

/* tablets (landscape) */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .banner {
    padding: 10%;

    .banner-text {
      padding-top: 20%;
    }

    .banner-image {
      width: 80%;
    }
  }

  .team {
    padding-top: 10%;

    .team-title {
      h1 {
        font-size: 75px;
      }
    }
    .person-info {
      visibility: visible;
      display: inline;

      .person-position {
        font-size: 15px;
        margin-bottom: 15%;
      }
    }
  }
}

/* iPad Pro (Portrait and Landscape) */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .banner {
    padding: 10%;

    .banner-text {
      padding-top: 20%;
    }

    .banner-image {
      width: 80%;
    }
  }

  .team {
    padding-top: 10%;

    .person-info {
      visibility: visible;
      display: inline;

      .person-position {
        font-size: 15px;
        margin-bottom: 15%;
      }
    }
  }
}


/* AddBlog component specific styles */

.container.add-blog {
  margin-top: 100px; /* Adjust the top margin to create space below the header */
}

.form-group {
  margin-bottom: 20px; /* Adjust spacing between form groups */
}

.form-control {
  background-color: #272626; /* Custom background color */
  color: white; /* Text color */
  border-color: #333; /* Border color */
}

.btn-primary {
  background-color: #7ced03; /* Primary button background color */
  border-color: #7ced03; /* Primary button border color */
  color: #fff; /* Button text color */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container.add-blog {
    margin-top: 80px; /* Adjust margin for smaller screens */
  }
}


// Update